import { isInAppFlag } from '@/utils/utilBridge'
import { localUserToken } from '@/utils/utilLocalStorage'

const UtilToken = {
  getTokenFromLocalStorage: () => {
    const userToken = localUserToken.load()
    return userToken !== undefined || userToken ? userToken.token : ''
  },
  getToken: () => {
    if (typeof window !== 'undefined' && isInAppFlag) {
      const apiToken = window.getToken().apiToken
      return apiToken.token === '' ? undefined : apiToken
    } else {
      return UtilToken.getTokenFromLocalStorage()
    }
  },
}

export { UtilToken }
