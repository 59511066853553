import { useMemo } from 'react'
import * as Sentry from '@sentry/nextjs'
import { useGetTestGroups } from '@/apis/rest/generated/hooks'
import { ABTestGroupCaseEnum, ABTestGroupCodeEnum } from '@/apis/rest/generated/types'
import { LocalABFlagEnum } from '@/constants/local-abflag.enum'
import { useCustomRouter, utilABTest } from '@/containers/hooks/index'
import { ABTest } from '@/utils/ab-test/utilAbtest.util'
import { getCodesByRate } from '@/utils/getCodesByRate'
import { createDeviceUuid } from '@/utils/utilCrypto'

interface UseAbTestProps {
  abKey: ABTestGroupCaseEnum
  pathname: string
}

export const useAbTest = ({ abKey, pathname }: UseAbTestProps) => {
  const { query } = useCustomRouter()
  const abQuerySting = (query.ab as string)?.toUpperCase()
  const getLocalStorageAB = utilABTest().getAB(LocalABFlagEnum.Global)
  const uuid = createDeviceUuid()
  const hash = ABTest.getHashByPathnameAndUuid(pathname, uuid)
  const userDistinctValueByHash = ABTest.getUserDistinctValueByHash(hash)

  const { data: abGroupData, isLoading: isAbLoading } = useGetTestGroups(
    {
      testCase: abKey,
    },
    {
      query: {
        enabled: !!abKey,
        select: (res) => res[0],
        onError: (error) => {
          Sentry.captureMessage(`AB 테스트 호출 에러 ${abKey}`)
          Sentry.captureException(error)
        },
      },
    }
  )

  const abCode = useMemo(() => {
    return abQuerySting || getLocalStorageAB || getCodesByRate(abGroupData?.groups)[userDistinctValueByHash]
  }, [abQuerySting, getLocalStorageAB, abGroupData, userDistinctValueByHash]) as ABTestGroupCodeEnum

  return {
    ab: abGroupData?.groups?.find((group) => group.code === abCode),
    uuid,
    hash,
    userDistinctValueByHash,
    isAbLoading,
    testPathname: pathname,
    abCode,
  }
}
