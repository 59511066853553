import Router from 'next/router'
import { LocationEnum, PathToLocation } from '@/constants/location.enum'
import { OrderButtonsTrackInfoType } from '@/containers/hooks/order/useOrderConfirm'
import { IModelProduct } from '@/containers/models/modelProduct'
import { CartShippingGroupType, CartShippingGroupTypeUtil } from '@/containers/types/CartShippingGroup'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { GraphQLProductType } from '@/utils/product/product-card.util'

type TrackingCategoryProps = {
  categoryId: string
  categoryName: string
}

type TrackingProductOptionProps = {
  productOptionId: string
  productOptionName: string
  productOptionQuantity?: number
  productOptionPurchasePrice?: number
}

type TrackingProductProps = {
  productId: string
  productName: string
  categories: TrackingCategoryProps[]
  productOptions?: TrackingProductOptionProps[]
}

type TrackedProductInfoType = {
  productId: number | string // ProductType의 productNumber는 string이고 TypedProductType의 productNumber는 Int이기에 작성된 유니온 타입
  productName: string
  productPrice: number
  productDiscountRate: number
  productPurchasePrice: number
  productReviewAvgScore: number
  productReviewTotalCount: number
  productIsInStock: boolean
}

export const convertProductCardParams = (productData: ProductTypeDefinedByFrontend) => {
  const {
    id,
    name,
    price,
    salePrice,
    discountRate,
    reviewCount,
    reviewScore,
    isRealSoldOut,
    hasApplicableProductCoupon,
  } = productData
  const productIsInStock = !isRealSoldOut
  return {
    productId: id,
    productName: name,
    productPrice: price,
    productPurchasePrice: salePrice,
    productDiscountPrice: discountRate,
    isRealSoldOut,
    productReviewAvgScore: reviewScore,
    productReviewTotalCount: reviewCount,
    productIsInStock,
    is_shownprice_coupondiscounted: hasApplicableProductCoupon,
  }
}

export const convertHomeProductCardParams = (p: GraphQLProductType): TrackedProductInfoType => {
  return {
    productId: p.productNumber,
    productName: p.name as string,
    productPrice: p.price as number,
    productDiscountRate: p.finalDiscountRate as number,
    productPurchasePrice: p.productPromotionCustomerPrice as number,
    productReviewAvgScore: p.averageReviewScore as number,
    productReviewTotalCount: p.reviewCount as number,
    productIsInStock: !p.isRealSoldOut,
  }
}
// CLICK_CART_PAYMENT_BUTTON (구매시작)
export const convertProductOptionCartsParams = (cartShippingGroups: CartShippingGroupType[]) => {
  const products: TrackingProductProps[] = []

  cartShippingGroups.forEach((cartShippingGroup) => {
    cartShippingGroup.cartProducts
      .filter((cartProduct) => cartProduct.checked)
      .forEach((cartProduct) => {
        const { cartProductOptions, product_id, productName } = cartProduct
        const categories = cartProductOptions.length > 0 ? cartProductOptions[0].categories : []
        products.push({
          productId: product_id,
          productName,
          categories: categories.map((x) => {
            return { categoryId: x._id, categoryName: x.name }
          }),
        })
      })
  })
  const {
    totalProductOptionAmount: totalProductAmount,
    totalProductOptionDiscountAmount: totalProductDiscountAmount,
    totalShippingFee: totalShipmentAmount,
    totalPaymentAmount: totalCartAmount,
  } = CartShippingGroupTypeUtil.getCartShippingGroupAmount(cartShippingGroups)
  return {
    products,
    totalProductAmount,
    totalProductDiscountAmount,
    totalCartAmount,
    totalShipmentAmount,
  }
}

// CLICK_LIST_STARTPURCHASE_BUTTON 이벤트 파라미터
export const convertClickListStartpurchaseButton = (productData: IModelProduct) => {
  const {
    productNumber: productId,
    name: productName,
    price: productPrice,
    customerPrice: productPurchasePrice,
    discountRate: productDiscountRate,
    categories: _categories = [],
    reviewCount: productReviewTotalCount,
    averageReviewScore: productReviewAvgscore,
  } = productData
  const categories: TrackingCategoryProps[] = _categories.map((x) => {
    return {
      categoryId: x.id,
      categoryName: x.name,
    }
  })
  const addLocation = Object.keys(PathToLocation).includes(Router.pathname)
    ? PathToLocation[Router.pathname]
    : LocationEnum.Others
  return {
    addLocation,
    productId,
    productName,
    categories,
    productPrice,
    productPurchasePrice,
    productDiscountRate,
    productReviewAvgscore,
    productReviewTotalCount,
  }
}

export const convertConfirmOrderParams = (trackingInfo: OrderButtonsTrackInfoType) => {
  const { option_id: productOptionId, optionName: productOptionName, product_id: productId, productName } = trackingInfo

  return {
    productOptionId,
    productOptionName,
    productId,
    productName,
  }
}

export const convertGoReviewFromOrdersParams = (trackingInfo: OrderButtonsTrackInfoType) => {
  const { option_id: productOptionId, optionName: productOptionName, product_id: productId, productName } = trackingInfo

  return {
    productOptionId,
    productOptionName,
    productId,
    productName,
  }
}

export const convertAddToCartFromOrdersParams = (trackingInfo: OrderButtonsTrackInfoType) => {
  const { option_id: productOptionId, optionName: productOptionName, product_id: productId, productName } = trackingInfo

  return {
    productOptionId,
    productOptionName,
    productId,
    productName,
  }
}
