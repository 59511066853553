import { MutableRefObject } from 'react'
import styled from 'styled-components'
import { useGetGnbList } from '@/apis/rest/generated/hooks'
import { shineLinesCSS } from '@/components/common/skeleton/ShineLinesCSS'
import HomeNavigationBarItem from '@/components/domains/layouts/header/HomeNavigationBarItem'
import { SCREEN_SIZES } from '@/constants/legacy/constLayout'
import { usePetType } from '@/containers/hooks'
import { UtilToken } from '@/utils/utilToken'

type HomeNavigationBarListProps = {
  listRef: MutableRefObject<HTMLElement | null | undefined>
}

const HomeNavigationBarList = ({ listRef }: HomeNavigationBarListProps) => {
  const { getPetType } = usePetType()
  const petType = getPetType()

  const token = UtilToken.getToken()
  const { data: gnbList, isLoading } = useGetGnbList(
    { petType },
    {
      query: {
        queryKey: ['gnbList', petType, token],
      },
    }
  )

  const replaceToInnerLink = (link?: string) => {
    if (link === '/mall') return '/'
    return link?.replace('/mall', '')
  }

  if (isLoading) {
    return (
      <StyledNavigationSkeletonWrapper>
        {Array.from({ length: 6 }).map((_, index) => (
          <StyledNavigationSkeletonItemContainer key={index}>
            <StyledNavigationSkeletonItem order={index} />
          </StyledNavigationSkeletonItemContainer>
        ))}
      </StyledNavigationSkeletonWrapper>
    )
  }

  return (
    <nav id="global-navigation">
      <StyledNavigationList>
        {gnbList?.map((item) => {
          const replacedInnerLink = replaceToInnerLink(item.link)
          return (
            <HomeNavigationBarItem
              key={item.id}
              title={item.name || ''}
              link={replacedInnerLink || ''}
              scrollCenter={(itemOffset: number, itemWidth: number) => {
                const screenWidth = window.screen.width > SCREEN_SIZES.md ? SCREEN_SIZES.md : window.screen.width
                const left = itemOffset - screenWidth / 2 + itemWidth / 2
                listRef?.current?.scrollTo({ left, behavior: 'smooth' })
              }}
              isBadge={item.isEmphasize}
              labelText={item.labelText}
            />
          )
        })}
      </StyledNavigationList>
    </nav>
  )
}

export default HomeNavigationBarList

const StyledNavigationList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 1.6rem;
  width: max-content;
`

const StyledNavigationSkeletonWrapper = styled.div`
  display: flex;
  gap: 0.4rem;
  padding: 0 1.6rem;
`

const StyledNavigationSkeletonItemContainer = styled.div`
  padding: 0.8rem 0 1.2rem;
`

const NavigationSkeletonItems: Record<number, string> = {
  1: '3rem',
  2: '8rem',
  3: '6rem',
  4: '6rem',
  5: '8rem',
  6: '6rem',
}

const StyledNavigationSkeletonItem = styled.div<{ order: number }>`
  ${shineLinesCSS};
  width: ${({ order }) => NavigationSkeletonItems[order]};
  height: 2.2rem;
  border-radius: 0.4rem;
  margin-right: 0.8rem;
`
