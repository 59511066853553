import crypto from 'crypto'

const UuidSliceLength = 12
const HashSliceLength = 12
const ModValue = 10
const Hexadecimal = 16

const ABTest = {
  /**
   * (uuid 의 마지막 12자리를 10진수로 변환한 값, uuid 가 없을 경우 0) mod 10 * 10 한 값을 리턴
   */
  getUserDistinctValue: (uuid?: string) => {
    const decimal = uuid && uuid.length > UuidSliceLength ? parseInt(uuid.slice(-UuidSliceLength), Hexadecimal) : 0
    return decimal % ModValue
  },
  /**
   * (hash 의 마지막 12자리를 10진수로 변환한 값, hash 가 없을 경우 0) mod 10 * 10 한 값을 리턴
   */
  getUserDistinctValueByHash: (hash?: string) => {
    const decimal = hash && hash.length > HashSliceLength ? parseInt(hash.slice(-HashSliceLength), Hexadecimal) : 0
    return decimal % ModValue
  },
  getHashByPathnameAndUuid: (pathname: string, uuid?: string) => {
    return crypto.createHash('sha256').update(`${pathname}${uuid}`).digest('hex')
  },
}

export { ABTest }
