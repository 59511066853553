import { FC } from 'react'
import styled from 'styled-components'
import { ProductsListItemResponseDto } from '@/apis/rest/generated/types'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import { PRODUCT_CARD_SIZES } from '@/components/domains/products/new-product-card/new-product-card.type'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { MinCountForScrollContainerEnum } from '@/constants/min-count-for-scroll-container.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { RecommendProductListTypeEnum } from '@/constants/recommend-product-list-type.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter, useIntersectionObserver } from '@/containers/hooks'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import ScrollContainer from '../etc/ScrollContainer'

interface ProductRecentlySliderProps {
  data?: ProductsListItemResponseDto[]
}

const ProductRecentlySliderWithAddToCart: FC<ProductRecentlySliderProps> = ({ data }) => {
  const { push, basePath, asPath } = useCustomRouter()
  const { triggerCustomEvent } = useTracker()

  const tracker = useTracker()

  const { elementRef } = useIntersectionObserver(
    () => {
      tracker.triggerCustomEvent(FitpetMallEventEnum.ViewProductdetailBottomProductSecond, {
        listType: RecommendProductListTypeEnum.RecentProduct,
      })
    },
    { rootMargin: '0px' }
  )

  const currentPath = `${basePath}${asPath}`

  if (!data || !data.length) {
    return null
  }

  return (
    <StyledContainer>
      <StyledTitleSection>
        <StyledTitle>최근 본 상품</StyledTitle>
        <StyledTextButton
          onClick={() =>
            push({ pathname: ROUTES.MYPAGE.FAVORITE_LIST.VIEW, query: { [QueryStringKeyEnum.PrevPath]: currentPath } })
          }
        >
          <span>더 보기</span>
          <FlatIcon type={IconNameEnum.IcArrowRightThin} color={GlobalColorEnum.Gray500} size={IconSizeEnum.Size16} />
        </StyledTextButton>
      </StyledTitleSection>

      {/* 최근 본 상품 리스트 */}
      <StyledContainer ref={elementRef}>
        <ScrollContainer
          hideRightButton={data.length < MinCountForScrollContainerEnum.RecentlyViewedProduct}
          buttonPositionY={30}
        >
          <SingleRowContainer>
            {data?.map((product, index) => (
              <StyledCardWrapper key={product.id}>
                <NewProductCard
                  product={transformProductTypeDefinedByFrontend(product)}
                  size={PRODUCT_CARD_SIZES.lg}
                  onClickProductCard={() => {
                    triggerCustomEvent(FitpetMallEventEnum.ClickSearchRecentProduct, {
                      listIndex: index,
                    })
                  }}
                  hasCartButton
                />
              </StyledCardWrapper>
            ))}
          </SingleRowContainer>
        </ScrollContainer>
      </StyledContainer>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${(props) => props.theme.color.grayWhite};
`

const StyledTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`

const StyledTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.color.gray900};
`

const StyledTextButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    display: inline-flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: ${(props) => props.theme.color.gray500};
  }
`

const SingleRowContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0.6rem;

  & > *:first-child {
    padding-left: 16px;
  }

  & > *:last-child {
    padding-right: 16px;
  }
`

const StyledCardWrapper = styled.div`
  margin-left: 6px;

  &:first-child {
    margin-left: 0;
  }
`

export default ProductRecentlySliderWithAddToCart
