import { ProductScheme } from '@/apis/rest/generated/types'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { ProductLocation, ProductLocationTrackerValue } from '@/constants/product-location.const'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { IModelProduct } from '@/containers/models/modelProduct'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { convertToKoreanValueFromSupplyType } from '@/utils/event-tracker/events'
import { GraphQLProductType } from '@/utils/product/product-card.util'
import { decodeIdForEventTracker } from '@/utils/utilApi'

export const useProductListEventTracker = () => {
  const tracker = useTracker()

  /**
   * 상품 진열 리스트에서 장바구니 담기 버튼을 클릭했을 때
   */
  const trackClickListStartPurchaseButtonEvent = ({
    product,
    searchKeyword,
    addLocation,
    sectionName,
    sectionIndex,
    listIndex,
  }: {
    product: GraphQLProductType
    searchKeyword?: string
    addLocation?: ProductLocation
    sectionName?: string
    sectionIndex?: number
    listIndex?: number
  }) => {
    const properties = {
      add_location: addLocation ? ProductLocationTrackerValue[addLocation] : undefined,
      list_index: listIndex,
      product_id: decodeIdForEventTracker(product.id),
      product_name: product.name,
      supply_type: convertToKoreanValueFromSupplyType(product.supplyType),
      brand_id: decodeIdForEventTracker(product.brand?.id),
      brand_name: product.brand?.name,
      category_ids: product.categories?.edges?.map((category) => decodeIdForEventTracker(category.node?.id)),
      category_names: product.categories?.edges?.map((category) => category.node?.name),
      product_price: product.price,
      product_purchase_price: product.productPromotionCustomerPrice,
      product_discount_rate: product.discountRate,
      product_review_total_count: product.reviewCount,
      section_index: sectionIndex,
      section_name: sectionName,
      search_keyword: searchKeyword,
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListStartPurchaseButton, properties)
  }

  const trackClickListStartPurchaseButtonEventV2 = ({
    product,
    searchKeyword,
    addLocation,
    sectionName,
    sectionIndex,
    listIndex,
  }: {
    product: ProductTypeDefinedByFrontend
    searchKeyword?: string
    addLocation?: ProductLocation
    sectionName?: string
    sectionIndex?: number
    listIndex?: number
  }) => {
    const properties = {
      add_location: addLocation ? ProductLocationTrackerValue[addLocation] : undefined,
      list_index: listIndex,
      product_id: product.id,
      product_name: product.name,
      supply_type: convertToKoreanValueFromSupplyType(product.supplyType),
      brand_id: product.brand.id,
      brand_name: product.brand?.name,
      category_ids: product.categories?.map((category) => category.id),
      category_names: product.categories?.map((category) => category.name),
      product_price: product.price,
      product_purchase_price: product.salePrice,
      product_discount_rate: product.discountRate,
      product_review_total_count: product.reviewCount,
      section_index: sectionIndex,
      section_name: sectionName,
      search_keyword: searchKeyword,
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListStartPurchaseButton, properties)
  }

  const trackClickListStartPurchaseButtonV4Event = ({
    product,
    searchKeyword,
    addLocation,
    sectionName,
    sectionIndex,
    listIndex,
  }: {
    product?: ProductScheme
    searchKeyword?: string
    addLocation?: ProductLocation
    sectionName?: string
    sectionIndex?: number
    listIndex?: number
  }) => {
    const properties = {
      add_location: addLocation ? ProductLocationTrackerValue[addLocation] : undefined,
      list_index: listIndex,
      product_id: product?.id,
      product_name: product?.name,
      supply_type: product?.supplyType,
      brand_id: product?.brand?.id,
      brand_name: product?.brand?.name,
      category_ids: product?.categories?.map((category) => category?.id),
      category_names: product?.categories?.map((category) => category.name),
      product_price: product?.price,
      product_purchase_price: product?.salePrice,
      product_discount_rate: product?.discountRate,
      product_review_total_count: product?.review?.count,
      section_index: sectionIndex,
      section_name: sectionName,
      search_keyword: searchKeyword,
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListStartPurchaseButton, properties)
  }

  /**
   * 상품 진열 리스트에서 장바구니 담기 버튼을 클릭했을 때
   */
  const trackClickListStartPurchaseButtonEventInAddToCart = ({
    product,
    searchKeyword,
    addLocation,
    sectionName,
    sectionIndex,
    listIndex,
  }: {
    product: IModelProduct
    searchKeyword?: string
    addLocation?: ProductLocation
    sectionName?: string
    sectionIndex?: number
    listIndex?: number
  }) => {
    const properties = {
      add_location: addLocation ? ProductLocationTrackerValue[addLocation] : undefined,
      list_index: listIndex,
      product_id: decodeIdForEventTracker(product.id),
      product_name: product.name,
      supply_type: convertToKoreanValueFromSupplyType(product.supplyType),
      brand_id: decodeIdForEventTracker(product.brand?.id),
      brand_name: product.brand?.name,
      category_ids: product.categories?.map((category) => decodeIdForEventTracker(category.id)),
      category_names: product.categories?.map((category) => category.name),
      product_price: product.price,
      product_purchase_price: product.productPromotionCustomerPrice,
      product_discount_rate: product.discountRate,
      product_review_total_count: product.reviewCount,
      section_index: sectionIndex,
      section_name: sectionName,
      search_keyword: searchKeyword,
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListStartPurchaseButton, properties)
  }

  /**
   * 상품 진열 리스트에서 장바구니 담기 버튼을 클릭했을 때
   */
  const trackClickListStartPurchaseButtonEventInAddToCart2 = ({
    product,
    searchKeyword,
    addLocation,
    sectionName,
    sectionIndex,
    listIndex,
    isNudge,
    location,
    listType,
    originProductId,
    originProductName,
  }: {
    product: ProductTypeDefinedByFrontend
    searchKeyword?: string
    addLocation?: ProductLocation
    sectionName?: string
    sectionIndex?: number
    listIndex?: number
    isNudge?: boolean
    location?: string
    listType?: string
    originProductId?: number
    originProductName?: string
  }) => {
    const properties = {
      add_location: addLocation ? ProductLocationTrackerValue[addLocation] : undefined,
      list_index: listIndex,
      product_id: product.id,
      product_name: product.name,
      supply_type: convertToKoreanValueFromSupplyType(product.supplyType),
      brand_id: product.brand?.id,
      brand_name: product.brand.name,
      category_ids: product.categories?.map((category) => category.id),
      category_names: product.categories?.map((category) => category.name),
      product_price: product.price,
      product_purchase_price: product.salePrice,
      product_discount_rate: product.discountRate,
      product_review_total_count: product.reviewCount,
      section_index: sectionIndex,
      section_name: sectionName,
      search_keyword: searchKeyword,
      isNudge,
      location,
      list_type: listType,
      cum_sales_skuquantity: product.salesQuantity,
      max_mileage: product.expectedMileage,
      originProductId,
      originProductName,
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListStartPurchaseButton, properties)
  }

  /**
   *
   * 담기 버튼 클릭했을때 이벤트
   * REST API, GraphQL 응답값을 받아 변환시킨 데이터를 받는 경우
   */
  const trackClickListStartPurchaseButtonEventRenewal = ({
    id,
    name,
    brandId,
    brandName,
    categoryIds,
    categoryNames,
    price,
    salePrice,
    discountRate,
    reviewCount,
    supplyType,
    searchKeyword,
    addLocation,
    sectionName,
    sectionIndex,
    listIndex,
  }: {
    id: number
    name: string
    brandId?: number
    brandName?: string
    categoryIds?: number[]
    categoryNames?: string[]
    price: number
    salePrice: number
    discountRate?: number
    reviewCount: number
    supplyType: string
    searchKeyword?: string
    addLocation?: ProductLocation
    sectionName?: string
    sectionIndex?: number
    listIndex?: number
  }) => {
    const properties = {
      product_id: id,
      product_name: name,
      brand_id: brandId,
      brand_name: brandName,
      category_ids: categoryIds,
      category_names: categoryNames,
      product_price: +price, // 강제 GraphQL Type으로 변환함 따라서 string으로 내려오는걸 강제로 number로 변환
      product_purchase_price: +salePrice,
      product_discount_rate: discountRate,
      product_review_total_count: reviewCount,
      supply_type: supplyType,
      add_location: addLocation ? ProductLocationTrackerValue[addLocation] : undefined,
      list_index: listIndex,
      section_index: sectionIndex,
      section_name: sectionName,
      search_keyword: searchKeyword,
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListStartPurchaseButton, properties)
  }

  return {
    trackClickListStartPurchaseButtonEvent,
    trackClickListStartPurchaseButtonEventInAddToCart,
    trackClickListStartPurchaseButtonEventInAddToCart2,
    trackClickListStartPurchaseButtonV4Event,
    // 상품 쿠폰 작업 시 추가한 이벤트 트래커
    trackClickListStartPurchaseButtonEventRenewal,
    trackClickListStartPurchaseButtonEventV2,
  }
}
