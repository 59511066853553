import { ABTestGroupResponseDtoGroupsItem } from '@/apis/rest/generated/types'

const AB_TEST_RATE_DIVISOR = 10
// ab group의 rate 비율만큼 code를 배열로 생성
// A: 60, B:40 => [A, A, A, A, A, A, B, B, B, B]

export const getCodesByRate = (abGroups: ABTestGroupResponseDtoGroupsItem[] = []): string[] => {
  if (abGroups.length === 0) {
    return []
  }
  return abGroups.flatMap((group) => Array(Math.round(group.rate / AB_TEST_RATE_DIVISOR)).fill(group.code))
}
